import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommentIcon from '@material-ui/icons/Comment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LaunchIcon from '@material-ui/icons/Launch';
import PersonIcon from '@material-ui/icons/Person';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import TodayIcon from '@material-ui/icons/Today';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WorkIcon from '@material-ui/icons/Work';

import { dateInTz, convertMinsToHrsMins } from '../../helpers/functions/dates';
import { getUserTimezone } from '../../helpers/functions/user';
import { addThreeDots, isObjEmpty, objectMapToArray } from '../../helpers/functions/utils';
import MediaGroups from '../../components/commons/orders/mediaGroups/MediaGroups';
import ClientRating from '../../components/commons/orders/rating/Rating';
import translation from '../../translation/translation';
import config from '../../config';

function DownloadIconButton({link}) {

  function handleDownloadMedia() {
    const url = link + '/download?client_id=' + config.api_key;
    const downloadIframe = document.getElementById('frame-download');
    const iframeContainer =  document.getElementById('iframes-container');
  
    if (downloadIframe) {
      downloadIframe.setAttribute('src', url);
    }
    else {
      const downloadIframe =  '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +  url + ' />';
      iframeContainer.innerHTML = downloadIframe;
    }
  }

  return (
    <IconButton
      aria-label="download"
      style={{padding: 7, color: "#FFFFFF"}}
      onClick={handleDownloadMedia}
      size="small"
    >
      <span className="icon-download" style={{fontSize: 12}} />
    </IconButton>
  );
}

DownloadIconButton.propTypes = {
  link: PropTypes.string.isRequired,
};

class Order extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isRatingOpen: false,
      ratingValue: null
    };

    this.handleUploadOrder = this.handleUploadOrder.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
    this.handleOpenRateModal = this.handleOpenRateModal.bind(this);
    this.handleCloseRateModal = this.handleCloseRateModal.bind(this);
    this.handleSubmitRating = this.handleSubmitRating.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      getOrder,
      match,
    } = this.props;

    if (match && match.params && match.params.id)
      getOrder(match.params.id);
  }

  renderOptions(options) {

    if (!options) return;

    let optionsChips = [];

    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        optionsChips.push(
          <Chip
            key={i}
            className="option"
            label={"Option : " + options[i].name}
          />
        );
      }
    }

    return optionsChips;
  }

  renderAttributes(attr) {
    switch (attr.type) {
      case "file":
        return (
          <DownloadIconButton link={attr.value} />
        );
      case "image":
        return (
          <DownloadIconButton link={attr.value} />
        );
      default:
        return (
          attr.value
        );
    }
  }

  handleUploadOrder() {

    const {
      getOrder,
      uploadOrder,
      orderProductMedias,
      order: {
        data,
        upload_action
      }
    } = this.props;

    if (upload_action.isLoading)
      return;

    if (orderProductMedias && data.id) {

      uploadOrder(
        orderProductMedias,
        data.id,
        () => {
          getOrder(data.id);
        }
      );
    }
  }

  handleOpenRateModal() {
    this.setState({ isRatingOpen: true });
  }

  handleCloseRateModal() {
    this.setState({ isRatingOpen: false });
  }

  handleSubmitRating(values) {

    if (!values || !values.rating)
      return;

    const {
      order: { data },
      rateClient
    } = this.props;

    rateClient(
      values,
      data.id,
      () => {
        this.setState({ ratingValue: values.rating });
        this.handleCloseRateModal();
      }
    );
  }

  renderOrder(data) {

    if (!data) return;

    const {
      getProductMediaGroups,
      clearMediaGroups,
      orderProductMedias,
      order: {
        mediaGroups,
        upload_action,
        rating,
      },
      user,
      provider
    } = this.props;

    const {
      isRatingOpen,
      ratingValue
    } = this.state;

    const timezoneCode = data.order && data.order.property && data.order.property.timezone_code ? data.order.property.timezone_code : undefined;

    const checkIsOrderReadyToSend = () => {

      let mediaTypesReady = 0;

      if (orderProductMedias && Object.keys(orderProductMedias).length) {

        for (let mediaType in orderProductMedias) {

          const nbMedias = orderProductMedias[mediaType].medias ? orderProductMedias[mediaType].medias.length : 0;
          const nbMin = orderProductMedias[mediaType].quantity_min ? orderProductMedias[mediaType].quantity_min : 0;
          const nbMax = orderProductMedias[mediaType].quantity_max ? orderProductMedias[mediaType].quantity_max : 10000000;

          if (nbMedias >= nbMin && nbMedias <= nbMax)
            mediaTypesReady++;

        }

        if (mediaTypesReady === Object.keys(orderProductMedias).length)
          return true;
        else
          return false
      }
      else
        return false;
    }

    let backgroundImage = "/assets/images/account/order-planned.jpg";

    if (
      data.delivery_status &&
      data.delivery_status.shortcode &&
      data.delivery_status.shortcode === "done"
    ) {
      backgroundImage = "/assets/images/account/order-done.jpg";
    }

    if (data.cover_url)
      backgroundImage = data.cover_url;

    const coverStyle = {
      backgroundImage: "url(" + backgroundImage + ")",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }

    return (
      <div className="Order open">
        { upload_action.isLoading &&
          <div className="upload-order-overlay">
            <div className="center-loader">
              <CircularProgress color="primary" size={65} />
            </div>
          </div>
        }
        <div className="order-wrapper-no-resize">
          <div className="container">
            <div className="order-content">
              <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4">
                <div className="shadow-wrapper">
                  <div className="order-cover" style={coverStyle}/>
                  <div className="overview">
                    <div className="col-sm-12">
                      <p className="order-adres">
                        {data.order && data.order.property && data.order.property.address ? addThreeDots(data.order.property.address, 82) : ''}
                        <a
                          target="_blank"
                          href={"http://maps.google.com?q=" + data.order.property.address}
                          rel="noopener noreferrer"
                          className="launch-address-map"
                        >
                          <LaunchIcon className="launch-address-icon"/>
                        </a>
                      </p>
                    </div>
                    <div className="clearfix"/>
                    <div className="timeline">
                      <div className="col-xs-5 planning">
                        {data.start ? <span><span className="planning-icon icon-calendrier" /> {dateInTz(data.start, 'localized-date') }</span> : <br/>}
                      </div>
                      <div className="col-xs-3 planning">
                        {data.start ? <span><span className="planning-icon icon-horaire" /> { dateInTz(data.start, 'HH:mm') }</span> : <br/>}
                      </div>
                      <div className="col-xs-4 status" style={{color: data.delivery_status.color ? data.delivery_status.color : '#000000'}}>
                        <img
                          alt="Status"
                          src={data.delivery_status && data.delivery_status.icon_url_dark ? data.delivery_status.icon_url_dark : ''}
                          className="status-img"
                        />
                        {data.delivery_status && data.delivery_status.name ? data.delivery_status.name : ''}
                      </div>
                      { getUserTimezone() !== timezoneCode &&
                        <div className="col-xs-12">
                          <div className="property-tz-warn">
                            <span className="icon-infos" /> {translation().commons.local_datetime} {dateInTz(data.start, 'HH:mm', timezoneCode)} ({timezoneCode})
                          </div>
                        </div>
                      }
                      <div className="clearfix"/>
                    </div>
                    <div className="time-track">
                      <div className="tracker"/>
                    </div>
                    <div className="products-img">
                      <div className={"product-img " + (data.uploadable ? "" : "unavailable")}>
                        <img src={data.icon_url_dark ? data.icon_url_dark : ''} alt={data.name ? data.name : "Missing img"} />
                      </div>
                      <span className={"name " + (data.uploadable ? "" : "unavailable")}>{data.name ? addThreeDots(data.name, 35) : "" }</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-5 col-md-6 col-lg-6">
                { data.name && <h2>{data.name}</h2> }
                <div className="details">
                  { data.id &&
                    <div className="detail-row">
                      { data.order_id &&
                        <span className="detail">
                          <span className="detail-icon"><ShoppingCartIcon fontSize="small"/></span> #{data.order_id}
                        </span>
                      }
                    </div>
                  }
                  { data.start  &&
                    <div className="detail-row">
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.date}><TodayIcon fontSize="small" /></span>
                        { dateInTz(data.start, "localized-date") }
                      </span>
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.time}><AccessTimeIcon fontSize="small" /></span>
                        { dateInTz(data.start, 'HH:mm') }
                      </span>
                    </div>
                  }
                  { getUserTimezone() !== timezoneCode &&
                    <div className="property-tz-warn">
                      <span className="icon-infos" /> {translation().commons.local_datetime} {dateInTz(data.start, "localized-datetime", timezoneCode)} ({timezoneCode})
                    </div>
                  } 
                  <div className="detail-row">
                    { data.duration &&
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.duration}><TimelapseIcon fontSize="small"/></span>
                        { convertMinsToHrsMins(data.duration, "h") }
                      </span>
                    }
                    { data.order &&
                      data.order.property &&
                      data.order.property.size_formatted &&
                      <span className="detail">
                        <span className="detail-icon icon-surface" title={translation().orders.size} />
                        { data.order.property.size_formatted }
                      </span>
                    }
                  </div>
                  { data.provider_name && user.data.company && user.data.company_admin &&
                    <div className="detail-row">
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.provider_name}><WorkIcon fontSize="small"/></span> <span>{data.provider_name}</span>
                      </span>
                    </div>
                  }
                  { data && data.order && data.order.user &&
                    <div className="detail-row">
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.client}><PersonIcon fontSize="small" /></span> {data.order.user && data.order.user.first_name ? data.order.user.first_name + " "  : ''}
                        {data.order.user && data.order.user.last_name ? data.order.user.last_name  : ''}
                        {data.order.company && data.order.company.commercial_name ? ( " (" + data.order.company.commercial_name + ")"): ''}
                      </span>
                    </div>
                  }
                  { data && data.property_contact_name &&
                    <div className="detail-row">
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.person_on_place}><PersonPinCircleIcon fontSize="small"/></span> {data.property_contact_name}
                        { data.property_contact_linking_phone
                          ? (
                            <span className="detail-contact-phone">
                              <a href={"tel:"+data.property_contact_linking_phone}>({data.property_contact_linking_phone})</a>
                            </span>
                          )
                          : ''
                        }
                      </span>
                    </div>
                  }
                  <div className="detail-row">
                    { data && data.property_access_details &&
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.access_details}><VpnKeyIcon fontSize="small" /></span> {data.property_access_details}
                      </span>
                    }
                  </div>
                  <div className="detail-row">
                    { data && data.property_contact_comment &&
                      <span className="detail" style={{marginTop: 12}}>
                        <div className="detail-icon" title={translation().orders.comments}><CommentIcon fontSize="small"/></div>
                        <p className="detail-comments">{data.property_contact_comment}</p>
                      </span>
                    }
                  </div>
                  { data.amount_provider_reversion_tax_excluded_formatted && user && user.data && !user.data.company &&
                    <div className="detail-row">
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.payed}><CreditCardIcon fontSize="small"/></span> <span>{data.amount_provider_reversion_tax_excluded_formatted} {translation().orders.tax_excl} {data.settled ? "" : translation().orders.estimed_price}</span>
                      </span>
                    </div>
                  }
                  { data.amount_provider_reversion_tax_excluded_formatted && user && user.data && user.data.company && user.data.company_admin &&
                    <div className="detail-row">
                      <span className="detail">
                        <span className="detail-icon" title={translation().orders.payed}><CreditCardIcon fontSize="small"/></span> <span>{data.amount_provider_reversion_tax_excluded_formatted} {translation().orders.tax_excl} {data.settled ? "" : translation().orders.estimed_price}</span>
                      </span>
                    </div>
                  }
                  { data.attributes && !isObjEmpty(data.attributes) &&
                    <div className="options product-attributes">
                      {objectMapToArray(data.attributes, (prop, attr, index) => (
                        attr.value &&
                          <div className="detail-row" key={index}>
                            <span className="detail">
                              <span className="detail-icon" style={{fontSize: "inherit"}}>{attr.label}:</span> {this.renderAttributes(attr)}
                            </span>
                          </div>
                      ))}
                    </div>
                  }
                  { data.options && data.options.length > 0
                    ? (
                      <div className="options">
                        { this.renderOptions(data.options) }
                      </div>
                    )
                    : ''
                  }
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2" style={{textAlign: 'right'}}>
                { data.client_rating || ratingValue
                  ? (
                    <div className="client-rating">
                      <Rating
                        initialRating={data.client_rating || ratingValue}
                        readonly={true}
                        emptySymbol={<span className="icon-etoilepleine rate-icon" />}
                        fullSymbol={<span className="icon-etoilepleine rate-icon filled"/>}
                      />
                    </div>
                  )
                  : data.delivery_status && data.delivery_status.is_received ? (
                    <div className="client-rating">
                      <span onClick={this.handleOpenRateModal} className="trigger-rating">{translation().orders.rating.trigger_rating}</span>
                    </div>
                  ) : ''
                }
                { data.uploadable &&
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!checkIsOrderReadyToSend() || upload_action.isLoading}
                    onClick={this.handleUploadOrder}
                  >
                    <i className="material-icons" style={{marginRight: 5}}>unarchive</i>
                    <span>{translation().orders.send}</span>
                  </Button>
                }
              </div>
              <div className="clearfix"/>
              <MediaGroups
                getProductMediaGroups={getProductMediaGroups}
                clearMediaGroups={clearMediaGroups}
                mediaGroups={mediaGroups}
                product={data}
                providerId={provider.data.id}
              />
              <div className="col-xs-12" style={{textAlign: 'right'}}>
                { data.uploadable &&
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!checkIsOrderReadyToSend() || upload_action.isLoading}
                    onClick={this.handleUploadOrder}
                  >
                    <i className="material-icons" style={{marginRight: 5}}>unarchive</i>
                    <span>{translation().orders.send}</span>
                  </Button>
                }
              </div>
              <div className="clearfix"/>
              <Dialog
                open={isRatingOpen}
                onClose={this.handleCloseRateModal}
                aria-labelledby="client-rating-dialog-title"
                disableBackdropClick={rating.isLoading}
                maxWidth="lg"
                PaperProps={{
                  className: "client-rate-dialog"
                }}
              >
                <DialogTitle style={{textAlign: 'center'}} id="client-rating-dialog-title">
                  {translation().orders.rating.trigger_rating}
                </DialogTitle>
                <DialogContent>
                  <ClientRating
                    product={data}
                    propertyAddress={data.order.property.address}
                    onClose={this.handleCloseRateModal}
                    onSubmit={this.handleSubmitRating}
                    isLoading={rating.isLoading}
                  />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {

    const {
      order: {
        data,
        isLoading
      }
    } = this.props;

    return (
      <div className="App-view Orders Order-view">
        {
          data
            ? this.renderOrder(data)
            : !isLoading
              ? <div className="data-empty">{translation().orders.order_not_found}</div>
              : ''
        }
        { isLoading &&
          <div className="center-loader">
            <CircularProgress color="secondary" size={35} />
          </div>
        }
      </div>
    );
  }
}

Order.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape()
  }),
  order: PropTypes.shape({
    data: PropTypes.shape(),
    isLoading: PropTypes.bool,
    mediaGroups: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.any),
      isLoading: PropTypes.bool
    }),
    upload_action: PropTypes.shape({
      error: PropTypes.any,
      isLoading: PropTypes.bool
    }).isRequired,
    rating: PropTypes.shape({
      error: PropTypes.any,
      isLoading: PropTypes.bool
    }).isRequired,
  }),
  user: PropTypes.shape().isRequired,
  orderProductMedias: PropTypes.shape(),
  uploadOrder: PropTypes.func.isRequired,
  rateClient: PropTypes.func.isRequired,
  provider: PropTypes.shape().isRequired
}

export default Order;
