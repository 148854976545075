import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  createDate,
  createDateUtc
} from '../../helpers/functions/dates';
import {
  apiGetAvailabilities,
  apiPostAvailability,
  apiPutAvailability,
  apiPostUnavailability,
  apiPutUnavailability,
  apiDeleteAvailabilities,
  apiDeleteUnavailabilities,
  apiGetProviderCalendar,
  apiGetProviderHash
} from '../../helpers/functions/provider';
import {
  clearSchedule,
  selectScheduleCompanyUser,
  setCurrentTimeRange,setCurrentView,
  getCompanyUsers, getCompanyUsersSuccess, getCompanyUsersFailure, updateCompanyUsers,
  getProviderCalendar, getProviderCalendarSuccess, getProviderCalendarFailure,
  getHash, getHashSuccess, getHashFailure
} from '../../redux/actions/scheduleActions';
import {
  getAvailabilities, getAvailabilitiesSuccess, getAvailabilitiesFailure,
  postAvailability, postAvailabilitySuccess, postAvailabilityFailure,
  putAvailability, putAvailabilitySuccess, putAvailabilityFailure,
  deleteAvailability, deleteAvailabilitySuccess, deleteAvailabilityFailure,
  postUnavailability, postUnavailabilitySuccess, postUnavailabilityFailure,
  putUnavailability, putUnavailabilitySuccess, putUnavailabilityFailure,
  deleteUnavailability, deleteUnavailabilitySuccess, deleteUnavailabilityFailure
} from '../../redux/actions/providerActions';

import { enqueueSnackbar } from '../../redux/actions/notificationsActions';
import { fetchApi } from '../../helpers/functions/api';

import config from '../../config';
import translation from '../../translation/translation';

import Schedule from '../../views/schedule/Schedule';

const mapStateToProps = state => ({
  schedule: state.schedule,
  user: state.user,
  provider: state.provider
});

const mapDispatchToProps = dispatch => {

  function _formatJSDate(date) {

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  return {
    clearSchedule: () => {
      dispatch(clearSchedule());
    },
    setCurrentTimeRange: ({start, end}) => {

      if (!start || !end) return;

      dispatch(setCurrentTimeRange({start, end}));
    },
    setCurrentView: (view) => {
      if (!view) return;

      dispatch(setCurrentView(view));
    },
    selectScheduleCompanyUser: id => {
      dispatch(selectScheduleCompanyUser(id));
    },
    getProviderEvents: (slot, providerId = null, callbackSuccess, callbackFailure) => {

      dispatch(getProviderCalendar());

      let query = {};

      if (slot && slot.start && slot.end) {
        query = {
          start: slot.start,
          end: slot.end,
          types: "cart_product,order_product,availability,unavailability"
        }
      }

      apiGetProviderCalendar(
        providerId,
        query,
        data => {

          for (let i = 0; i < data.length; i++) {
            if (data[i].details && data[i].details.id)
              data[i].id = data[i].details.id
          }

          dispatch(getProviderCalendarSuccess(data));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();
        },
        error => {
          dispatch(getProviderCalendarFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();

          if (error.response) {
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.get_events_error,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(enqueueSnackbar({
              message: translation().schedule.get_events_error,
              options: {
                variant: 'error',
              },
            }));
          }
        }
      );
    },
    enqueueSnackbarSchedule: (message, options) => {

      if (!message || !options) return;

      dispatch(enqueueSnackbar({message, options}));
    },
    getAvailabilities: (slot, callbackSuccess, callbackFailure) => {

      dispatch(getAvailabilities());

      let query = {};

      if (slot && slot.start && slot.end) {
        query = {
          filters: "start|gte|" + slot.start + "end|lte|" + slot.end
        }
      }

      apiGetAvailabilities(
        query,
        data => {
          dispatch(getAvailabilitiesSuccess(data));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();
        },
        error => {
          dispatch(getAvailabilitiesFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();
        }
      );
    },
    postAvailability: (values, {start: eventStart}, callbackSuccess, callbackFailure) => {

      if (!values || !eventStart) return;

      const day = _formatJSDate(eventStart);
      const start = createDate(day + 'T' + values.hour_start + ':' + values.min_start + ':00');
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00');

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      /**
       * Trust API now, keep code saved anyway.
       */

      // const todayUtc = createDateUtc(new Date().toUTCString());

      // if (isDateBefore(startUtc, endUtc)) {
      //   dispatch(enqueueSnackbar({
      //     message: translation().schedule.availability.create.callbacks.should_be_after,
      //     options: {
      //       variant: 'warning',
      //     },
      //   }));
      //   return;
      // }
      // else if (isDateSame(startUtc, endUtc)) {
      //   dispatch(enqueueSnackbar({
      //     message: translation().schedule.availability.create.callbacks.not_equal,
      //     options: {
      //       variant: 'warning',
      //     },
      //   }));
      //   return;
      // }
      //
      // if (isDateBefore(todayUtc, startUtc)) {
      //   dispatch(enqueueSnackbar({
      //     message: translation().schedule.availability.create.callbacks.should_be_futur,
      //     options: {
      //       variant: 'warning',
      //     },
      //   }));
      //   return;
      // }

      dispatch(postAvailability());

      const availability = {
        start: startUtc,
        end: endUtc
      }

      apiPostAvailability(
        availability,
        success => {
          /**
           * We need to force some keys in redux for use function binded by ids
           */
          success.type = "availability";
          success.details = {};
          success.details.id = success.id;

          dispatch(postAvailabilitySuccess(success));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();

          dispatch(enqueueSnackbar({
            message: translation().schedule.availability.create.callbacks.success,
            options: {
              variant: 'success',
            },
          }));
        },
        error => {
          dispatch(postAvailabilityFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();

          if (error.response) {
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.availability.create.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
          }
          else {
            dispatch(enqueueSnackbar({
              message: translation().schedule.availability.create.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
          }
        },
      );
    },
    putAvailability: (id, values, eventStart, callbackSuccess, callbackFailure) => {

      if (!values || !eventStart) return;

      const day = _formatJSDate(eventStart);
      const start = createDate(day + 'T' + values.hour_start + ':' + values.min_start + ':00');
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00');

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      dispatch(putAvailability());

      const availability = {
        start: startUtc,
        end: endUtc
      }

      apiPutAvailability(
        id,
        availability,
        success => {
          /**
           * We need to force some keys in redux for use function binded by ids and type
           */
          success.type = "availability";
          success.details = {};
          success.details.id = success.id;

          dispatch(putAvailabilitySuccess(id, success));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();

          dispatch(enqueueSnackbar({
            message: translation().schedule.availability.edit.callbacks.success,
            options: {
              variant: 'success',
            },
          }));
        },
        error => {
          dispatch(putAvailabilityFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();

          if (error.response)
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.availability.edit.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
          else
            dispatch(enqueueSnackbar({
              message: translation().schedule.availability.edit.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
        },
      );
    },
    deleteAvailability: (availabilityId, callbackSuccess, callbackFailure) => {
      dispatch(deleteAvailability());

      if (!availabilityId) return;

      apiDeleteAvailabilities(
        availabilityId,
        () => {
          dispatch(deleteAvailabilitySuccess(availabilityId));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();

          dispatch(enqueueSnackbar({
            message: translation().schedule.availability.delete.callbacks.success,
            options: {
              variant: 'success',
            },
          }));
        },
        error => {
          dispatch(deleteAvailabilityFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();

          if (error.response)
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.availability.delete.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
          else
            dispatch(enqueueSnackbar({
              message: translation().schedule.availability.delete.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
        },
      );
    },
    postUnavailability: (startDate, values, callbackSuccess, callbackFailure) => {

      if (
        !startDate || !values ||
        !values.hour_start || !values.min_start ||
        !values.hour_end || !values.min_end
      ) return;

      const day = _formatJSDate(startDate);
      const start = createDate(day + 'T' + values.hour_start + ':' + values.min_start + ':00');
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00');

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      if (!values.latitude || !values.longitude) {
        dispatch(enqueueSnackbar({
          message: translation().schedule.unavailability.create.callbacks.no_lat_lng,
          options: {
            variant: 'warning',
          },
        }));
        return;
      }

      dispatch(postUnavailability());

      const unavailability = {
        title: values.title ? values.title : '',
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
        start: startUtc,
        end: endUtc
      }

      apiPostUnavailability(
        unavailability,
        success => {

          const newUnavailability = {
            ...success,
            type: "unavailability",
            details: {
              id: success.id
            },
            location: {
              address: success.location,
              longitude: success.longitude,
              latitude: success.latitude
            }
          }

          dispatch(postUnavailabilitySuccess(newUnavailability));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();

          dispatch(enqueueSnackbar({
            message: translation().schedule.unavailability.create.callbacks.success,
            options: {
              variant: 'success',
            },
          }));
        },
        error => {
          dispatch(postUnavailabilityFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();

          if (error.response)
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.unavailability.create.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
          else
            dispatch(enqueueSnackbar({
              message: translation().schedule.unavailability.create.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
        },
      );
    },
    deleteUnavailability: (id, callbackSuccess, callbackFailure) => {
      dispatch(deleteUnavailability());

      if (!id) return;

      apiDeleteUnavailabilities(
        id,
        () => {
          dispatch(deleteUnavailabilitySuccess(id));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();

          dispatch(enqueueSnackbar({
            message: translation().schedule.unavailability.delete.callbacks.success,
            options: {
              variant: 'success',
            },
          }));
        },
        error => {
          dispatch(deleteUnavailabilityFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();

          if (error.response)
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.unavailability.delete.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
          else
            dispatch(enqueueSnackbar({
              message: translation().schedule.unavailability.delete.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
        },
      );
    },
    putUnavailability: (id, startDate, values, callbackSuccess, callbackFailure) => {

      if (
        !id ||
        !startDate ||
        !values ||
        !values.hour_start || !values.min_start ||
        !values.hour_end || !values.min_end
      ) return;

      const day = _formatJSDate(startDate);
      const start = createDate(day + 'T' + values.hour_start + ':' + values.min_start + ':00');
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00');

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      if (!values.latitude || !values.longitude) {
        dispatch(enqueueSnackbar({
          message: translation().schedule.unavailability.edit.callbacks.no_lat_lng,
          options: {
            variant: 'warning',
          },
        }));
        return;
      }

      dispatch(putUnavailability());

      const newValues = {
        title: values.title ? values.title : '',
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
        start: startUtc,
        end: endUtc
      }

      apiPutUnavailability(
        id,
        newValues,
        success => {

          const newUnavailability = {
            ...success,
            type: "unavailability",
            details: {
              id: success.id
            },
            location: {
              address: success.location,
              longitude: success.longitude,
              latitude: success.latitude
            }
          }

          dispatch(putUnavailabilitySuccess(id, newUnavailability));

          if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess();

          dispatch(enqueueSnackbar({
            message: translation().schedule.unavailability.edit.callbacks.success,
            options: {
              variant: 'success',
            },
          }));
        },
        error => {
          dispatch(putUnavailabilityFailure(error));

          if (callbackFailure && typeof callbackFailure === 'function')
            callbackFailure();

          if (error.response)
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.unavailability.edit.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
          else
            dispatch(enqueueSnackbar({
              message: translation().schedule.unavailability.edit.callbacks.error,
              options: {
                variant: 'error',
              },
            }));
        },
      );
    },
    getCompanyUsers: (next = null) => {
      dispatch(getCompanyUsers(next));

      fetchApi(
        "get",
        next ? next.replace(config.api_url, '') : "provider-companies/self/providers",
        null,
        null,
        null,
        (data, paging) => {
          next
            ? dispatch(updateCompanyUsers(data, paging))
            : dispatch(getCompanyUsersSuccess(data, paging));
        },
        error => {
          dispatch(getCompanyUsersFailure(error));
        }
      );
    },
    getHash: () => {
      dispatch(getHash());

      apiGetProviderHash(
        null,
        data => {
          dispatch(getHashSuccess(data));
        },
        error => {
          if (error.response)
            dispatch(enqueueSnackbar({
              message: error.response.data && error.response.data.detail ? error.response.data.detail : translation().schedule.sync_cal.error,
              options: {
                variant: 'error',
              },
            }));
          else
            dispatch(enqueueSnackbar({
              message: translation().schedule.sync_cal.error,
              options: {
                variant: 'error',
              },
            }));

          dispatch(getHashFailure());
        }
      );
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedule));
