import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TodayIcon from '@material-ui/icons/Today';

import translation from '../../../translation/translation';

class User extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isUnlinkCompanyUserDialogOpen: false,
      isAdminLoading: false,
      isDeleteLoading: false,
      checked: false
    }

    this.handleAdminizeUser = this.handleAdminizeUser.bind(this);
    this.handleUnlinkUser = this.handleUnlinkUser.bind(this);

    this.handleOpenUnlinkCompanyUserDialog = this.handleOpenUnlinkCompanyUserDialog.bind(this);
    this.handleCloseUnlinkCompanyUserDialog = this.handleCloseUnlinkCompanyUserDialog.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      isCompanyAdmin
    } = this.props;

    if (isCompanyAdmin)
      this.setState({ checked: true });
  }

  handleAdminizeUser(event, value) {

    const {
      adminizeCompanyUser,
      userId
    } = this.props;

    this.setState({ isAdminLoading: true });

    adminizeCompanyUser(
      userId,
      value ? true : false,
      () => {
        this.setState({
          checked: !this.state.checked,
          isAdminLoading: false
        });
      },
      () => {
        this.setState({
          checked: this.state.checked,
          isAdminLoading: false
        });
      }
    );
  }

  handleUnlinkUser() {

    const {
      getCompanyUsers,
      unlinkCompanyUser,
      userId
    } = this.props;

    this.setState({ isDeleteLoading: true });

    unlinkCompanyUser(
      userId,
      () => {
        getCompanyUsers();

        this.setState({
          isDeleteLoading: false,
          isUnlinkCompanyUserDialogOpen: false
        });
      },
      () => {
        this.setState({ isDeleteLoading: false });
      }
    );
  }

  handleOpenUnlinkCompanyUserDialog() {

    this.setState({
      isUnlinkCompanyUserDialogOpen: true
    });
  }

  handleCloseUnlinkCompanyUserDialog() {

    this.setState({
      isUnlinkCompanyUserDialogOpen: false
    });
  }

  render() {

    const {
      lastName,
      firstName,
      userLogged,
      userId,
      providerId,
    } = this.props

    const {
      isUnlinkCompanyUserDialogOpen,
      isAdminLoading,
      isDeleteLoading,
      checked
    } = this.state;

    const iconCheckStyle = {
      display: "block",
      textAlign: "right",
      padding: "12px 15px 12px 12px",
      fontSize: "16px",
    }

    const iconUncheckedStyle = {
      ...iconCheckStyle,
      color: "#48C17B"
    }

    const iconCheckedStyle = {
      ...iconCheckStyle,
      color: "#F1664F"
    }

    return (
      <TableRow>
        <TableCell>{lastName}</TableCell>
        <TableCell>{firstName}</TableCell>
        <TableCell align="right">
          { isAdminLoading
            ?
              <CircularProgress
                color="secondary"
                size={24}
                style={{margin: 12}}
              />
            :
            <span>
              { userLogged.id !== userId
                ?
                  <Checkbox
                    checked={checked}
                    onChange={this.handleAdminizeUser}
                    color="default"
                  />
                : checked
                  ? <span className="icon-check" style={iconUncheckedStyle} />
                  : <span className="icon-fermer" style={iconCheckedStyle} />
              }
            </span>
          }
        </TableCell>
        <TableCell align="right">
          <Link to={`schedule/${providerId}/${firstName}`}>
            <IconButton
              aria-label="calendar"
              style={{padding: 7, marginRight: 4}}
              color="inherit"
            >
              <TodayIcon fontSize="small" />
            </IconButton>
          </Link>
          <IconButton
            aria-label="delete"
            style={{padding: 7}}
            color='inherit'
            onClick={this.handleOpenUnlinkCompanyUserDialog}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
        <Dialog
          aria-labelledby="unlink-company-user-dialog"
          onClose={this.handleCloseUnlinkCompanyUserDialog}
          open={isUnlinkCompanyUserDialogOpen}
          disableEscapeKeyDown={false}
          disableBackdropClick={false}
          scroll={"paper"}
        >
          <DialogTitle id="unlink-company-user-dialog">
            {translation().company.members.dialogs.unlink.title}
          </DialogTitle>
          <DialogContent style={{minWidth: 300}}>
            <p>{translation().company.members.dialogs.unlink.description}</p>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              disabled={isDeleteLoading}
              onClick={this.handleCloseUnlinkCompanyUserDialog}
            >
              {translation().company.members.buttons.unlink.cancel}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{minWidth: 120}}
              disabled={isDeleteLoading}
              onClick={this.handleUnlinkUser}
            >
              {
                isDeleteLoading
                  ? <CircularProgress size={20} color='secondary' />
                  : <span>{translation().company.members.buttons.unlink.submit}</span>
              }
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
    );
  }
}

User.propTypes = {
  adminizeCompanyUser: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  unlinkCompanyUser: PropTypes.func.isRequired,
  isCompanyAdmin: PropTypes.bool,
  firstName: PropTypes.any.isRequired,
  lastName: PropTypes.any.isRequired,
  userId: PropTypes.any.isRequired,
  providerId: PropTypes.any.isRequired,
  userLogged: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }).isRequired,
}

export default User;
