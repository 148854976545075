import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Fab from '@material-ui/core/Fab';

// import LinkUsersForm from '../forms/users/LinkUsersForm';
import User from './User';

import translation from '../../../translation/translation';

class Users extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isInviteUserDialogOpen: false
    }

    this.handleScroll = this.handleScroll.bind(this);
    // this.handleLinkCompanyUsers = this.handleLinkCompanyUsers.bind(this);
    // this.handleOpenInviteUserDialog = this.handleOpenInviteUserDialog.bind(this);
    // this.handleCloseInviteUserDialog = this.handleCloseInviteUserDialog.bind(this);
  }

  UNSAFE_componentWillMount () {

    const {
      getCompanyUsers
    } = this.props;

    getCompanyUsers();
  }

  handleScroll(event) {

    const {
      getCompanyUsers,
      users: {
        paging,
        isLoading
      }
    } = this.props;

    if (!event || !event.target) return;

    if (!event.target.scrollTop || event.target.scrollTop === 0) return;

    if (event.target.scrollHeight - event.target.clientHeight - 20 <= event.target.scrollTop) {
      if (paging && paging.next) {
        if (!isLoading) {
          getCompanyUsers(paging.next);
        }
      }
    }
  }

  // handleOpenInviteUserDialog() {
  //
  //   this.setState({
  //     isInviteUserDialogOpen: true
  //   });
  // }
  //
  // handleCloseInviteUserDialog() {
  //
  //   this.setState({
  //     isInviteUserDialogOpen: false
  //   });
  // }
  //
  // handleLinkCompanyUsers(values) {
  //
  //   const {
  //     linkCompanyUsers
  //   } = this.props;
  //
  //   linkCompanyUsers(
  //     values,
  //     this.handleCloseInviteUserDialog
  //   );
  // }

  renderCompanyUsersTable(data) {

    const {
      getCompanyUsers,
      adminizeCompanyUser,
      unlinkCompanyUser,
      userLogged,
    } = this.props;

    if (data && data.length > 0 ) {

      const rows = [];

      for (let i = 0; i < data.length; i++) {
        let row = {};

        if (data[i].user && data[i].user.id)
          row['user_id'] = data[i].user.id;
        else
          row['user_id'] = 'NaN'

        if (data[i].id)
          row['provider_id'] = data[i].id;
        else
          row['provider_id'] = 'NaN'

        if (data[i].user && data[i].user.company_admin)
          row['company_admin'] = data[i].user.company_admin;

        if (data[i].user && data[i].user.last_name)
          row['last_name'] = data[i].user.last_name;
        else
          row['last_name'] = '-'

        if (data[i].user && data[i].user.first_name)
          row['first_name'] = data[i].user.first_name;
        else
          row['first_name'] = '-';

        rows.push(row);
      }

      return (
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translation().company.members.table.last_name}</TableCell>
                <TableCell>{translation().company.members.table.first_name}</TableCell>
                <TableCell align="right">{translation().company.members.table.admin}</TableCell>
                <TableCell align="right"/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.map((row, index) => {
                  return (
                    <User
                      key={index}
                      userId={row.user_id}
                      lastName={row.last_name}
                      firstName={row.first_name}
                      adminizeCompanyUser={adminizeCompanyUser}
                      isCompanyAdmin={row.company_admin}
                      getCompanyUsers={getCompanyUsers}
                      unlinkCompanyUser={unlinkCompanyUser}
                      userLogged={userLogged}
                      providerId={row.provider_id}
                    />
                  );
                })
              }
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {

    const {
      users: {
        list,
        isLoading,
        // users_actions
      }
    } = this.props;

    // const {
    //   isInviteUserDialogOpen
    // } = this.state;

    return (
      <div className="members" style={{marginTop: 25}}>
        {/* <div className="section-controls" style={{textAlign: 'right'}}>
          <Fab
            color="secondary"
            style={{marginLeft: 'auto'}}
            onClick={this.handleOpenInviteUserDialog}
            aria-label="Invit"
            size="medium"
          >
            <span className="icon-ajout_membre" style={{fontSize: 25}} />
          </Fab>
        </div> */}
        <div style={{width: "100%", overflow: "auto", maxHeight: 500}} onScroll={this.handleScroll}>
          { list && list.length > 0
            ? this.renderCompanyUsersTable(list)
            : !isLoading
              ? <div className="data-empty error-message">{translation().company.members.no_member}</div>
              : ''
          }
        </div>
        { isLoading &&
          <div className="center-loader">
            <CircularProgress color={'secondary'} size={35} />
          </div>
        }
        {/* <Dialog
          aria-labelledby="invite-company-user-dialog"
          onClose={this.handleCloseInviteUserDialog}
          open={isInviteUserDialogOpen}
          disableEscapeKeyDown={false}
          disableBackdropClick={false}
          scroll={"paper"}
          >
          <DialogTitle id="invite-company-user-dialog">
            {translation().company.members.dialogs.link.title}
          </DialogTitle>
          <DialogContent className="invite-user_dialog">
            <p>{translation().company.members.dialogs.link.description}</p>
            <LinkUsersForm
          onSubmit={this.handleLinkCompanyUsers}
          labels={translation().company.members.labels}
          usersActions={users_actions}
          buttonLabel={translation().company.members.buttons.link.submit}
            />
          </DialogContent>
          <DialogActions>
            <Button
          color="default"
          onClick={this.handleCloseInviteUserDialog}
            >
          {translation().company.members.buttons.link.close}
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    )
  }
}

Users.propTypes = {
  // linkCompanyUsers: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  adminizeCompanyUser: PropTypes.func.isRequired,
  unlinkCompanyUser: PropTypes.func.isRequired,
  userLogged: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }).isRequired,
  users: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape()
  }).isRequired,
}

export default Users;
