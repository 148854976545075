import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import TodayIcon from '@material-ui/icons/Today';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SyncIcon from '@material-ui/icons/Sync';

import {
  addToDate,
  alterDate,
  createDateUtc,
  dateInTz,
  formatDate,
  getDateRangeOfWeek,
  getWeekNumberFromJsDate,
  removeFromDate,
  toJsDate
} from '../../helpers/functions/dates';
import { getLocale } from '../../helpers/functions/cookies';
import { autoScrollTop, handleInfiniteScroll } from '../../helpers/functions/jquery';

import ScheduleToolbar from './ScheduleToolbar';
import ScheduleCalendar from './ScheduleCalendar';
import ScheduleEventWeek from './events/ScheduleEventWeek';
import ScheduleEventMonth from './events/ScheduleEventMonth';
import ScheduleEventPopup from './modals/ScheduleEventPopup';
import CreateAvailabilityPopup from './modals/CreateAvailabilityPopup';
import CreateUnavailabilityPopup from './modals/CreateUnavailabilityPopup';
import Provider from './providers/Provider';

import config from '../../config';

import translation from '../../translation/translation';

const HashLink = props => <a {...props}>{props.children}</a>

HashLink.propTypes = {
  children: PropTypes.node
};

const modalStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class Schedule extends Component {

  constructor(props) {
    super(props);

    this.state = {
      event: null,
      isPopupEventOpen: false,
      isCreateAvailabilityOpen: false,
      isCreateUnavailabilityOpen: false,
      isCompanyProvidersOpen: false,
      isHashDialogOpen: false,
    }

    this.minHour = 7;
    this.maxHour = 20;
    this.minTime = new Date();
    this.minTime.setHours(this.minHour, 0, 0);
    this.maxTime = new Date();
    this.maxTime.setHours(this.maxHour, 30, 0);

    this.step = 30;
    this.timeslots = 1;
    this.defaultView = window.innerWidth < 768 ? "day" : "week";
    this.defaultDate = new Date(formatDate(createDateUtc(), "iso"));

    this.messages = {
      allDay: "",
      previous: translation().schedule.calendar.previous,
      next: translation().schedule.calendar.next,
      today: translation().schedule.calendar.today,
      month: translation().schedule.calendar.month,
      week: translation().schedule.calendar.week,
      day: translation().schedule.calendar.day,
      agenda: translation().schedule.calendar.agenda,
      date: translation().schedule.calendar.date,
      time: translation().schedule.calendar.time,
      event: translation().schedule.calendar.event,
      showMore: nbSlots => {
        return "+" + nbSlots
      }
    }

    this.formats = {
      timeGutterFormat: 'HH:mm',
      dayFormat: 'ddd D/MM',
      dayHeaderFormat: 'dddd DD MMM YYYY',
      dayRangeHeaderFormat: ({ start, end }) =>
        translation().schedule.calendar.week + " " + start.toLocaleDateString(getLocale(), { month: 'long', day: 'numeric'}) + ' - ' +  end.toLocaleDateString(getLocale(), { month: 'long', day: 'numeric', year: 'numeric'}),
    }

    this.convertDataIntoEvent = this.convertDataIntoEvent.bind(this);
    this.getDrilldownView = this.getDrilldownView.bind(this);
    this.selectSlot = this.selectSlot.bind(this);
    this.setDayTimeRange = this.setDayTimeRange.bind(this);
    this.setWeekTimeRange = this.setWeekTimeRange.bind(this);
    this.setMonthTimeRange = this.setMonthTimeRange.bind(this);
    this.loadEventsView = this.loadEventsView.bind(this);

    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleOpenHashDialog = this.handleOpenHashDialog.bind(this);
    this.handleCloseHashDialog = this.handleCloseHashDialog.bind(this);
    this.handleCloseEventPopup = this.handleCloseEventPopup.bind(this);
    this.handleCloseCreateAvailability = this.handleCloseCreateAvailability.bind(this);
    this.handleOpenCreateUnavailability = this.handleOpenCreateUnavailability.bind(this);
    this.handleCloseCreateUnavailability = this.handleCloseCreateUnavailability.bind(this);
    this.handleOpenCompanyProviders = this.handleOpenCompanyProviders.bind(this);
    this.handleCloseCompanyProviders = this.handleCloseCompanyProviders.bind(this);
    this.handleGetCompanyMembers = this.handleGetCompanyMembers.bind(this);
    this.handleScrollCompanyProviders = this.handleScrollCompanyProviders.bind(this);
    this.handleSelectCompanyProvider = this.handleSelectCompanyProvider.bind(this);
    this.handleShowCompanyProviderCal = this.handleShowCompanyProviderCal.bind(this);
    this.createAvailability = this.createAvailability.bind(this);
    this.createUnavailability = this.createUnavailability.bind(this);
    this.editAvailability = this.editAvailability.bind(this);
    this.editUnavailability = this.editUnavailability.bind(this);
    this.removeAvailability = this.removeAvailability.bind(this);
    this.removeUnavailability = this.removeUnavailability.bind(this);
    this.renderCreateAvailability = this.renderCreateAvailability.bind(this);
    this.renderEventPopup = this.renderEventPopup.bind(this);
  }

  UNSAFE_componentWillMount () {

    const { selectScheduleCompanyUser, match } = this.props;

    autoScrollTop();
    this.loadEventsView(this.defaultView);

    if (match && match.params && match.params.id && match.params.firstName) {
      selectScheduleCompanyUser({id: match.params.id, firstName: match.params.firstName});
    }
  }

  componentDidMount() {
    Modal.setAppElement('#iframes-container');
  }

  componentWillUnmount() {
    this.props.clearSchedule();
  }

  convertDataIntoEvent(events) {
    const {
      schedule: { users }
    } = this.props;

    if (!events || (events.length <= 0))
      return [];

    const providerEvents = [];

    /**
     * Check all event types for build the right provider event
     */
    for (let i = 0; i < events.length; i++) {
      const timezoneCode = events[i].location && events[i].location.timezone_code ? events[i].location.timezone_code : undefined;
      
      if (events[i].type && events[i].type === "availability") {
        const event = {
          type: "availability",
          title: translation().schedule.availability.edit.title,
          writable: false,
          deletable: true,
          id: events[i]['details'] && events[i]['details']['id'] ? events[i]['details']['id'] : undefined,
          className: "event-availability",
          handleOpenCreateUnavailability: this.handleOpenCreateUnavailability,
          readOnly: users && users.selected ? true : false,
        };

        if (events[i]['start'])
          event.start = toJsDate(dateInTz(events[i]['start']));

        if (events[i]['end'])
          event.end = toJsDate(dateInTz(events[i]['end']));

        providerEvents.push(event);
      }
      else if (events[i].type && events[i].type === "unavailability") {

        const event = {
          type: "unavailability",
          title: events[i].title || translation().schedule.unavailability.edit.title,
          writable: false,
          deletable: true,
          location: events[i].location ? events[i].location : null,
          id: events[i].id || (events[i]['details'] && events[i]['details']['id'] ? events[i]['details']['id'] : undefined),
          className: "event-unavailability",
          readOnly: users && users.selected ? true : false,
        };

        if (events[i]['start'])
          event.start = toJsDate(dateInTz(events[i]['start']));

        if (events[i]['end'])
          event.end = toJsDate(dateInTz(events[i]['end']));

        providerEvents.push(event);
      }
      else if (events[i].type && events[i].type === "order_product") {

        const event = {
          type: "orderProduct",
          title: events[i]['title'] ? events[i]['title'] : translation().schedule.calendar.order,
          writable: false,
          deletable: false,
          order_id: null,
          className: "event-order-product",
          id: events[i]['details'] && events[i]['details']['id'] ? events[i]['details']['id'] : undefined,
          timezone_code: timezoneCode,
          product: {
            duration: null,
            name: null,
            property_size_formatted: null,
            icon_url_light: null,
            icon_url_dark: null,
            property: {},
            property_access_details: null,
            property_contact_comment: null,
            property_contact_name: null,
            property_contact_linking_phone: null,
            delivery_status: {},
            status: {},
            options: [],
          }
        };

        if (events[i]['start']) {
          event.start = toJsDate(dateInTz(events[i]['start']));
          event.local_start = dateInTz(events[i]['start'],  undefined, timezoneCode);
        }

        if (events[i]['end']) {
          event.end = toJsDate(dateInTz(events[i]['end']));
          event.local_end = dateInTz(events[i]['end'], undefined, timezoneCode);
        }

        if (events[i]['details'] && events[i]['details']['order_id'])
          event.order_id = events[i]['details']['order_id'];

        if (events[i]['title'])
          event.product.name = events[i]['title'];

        if (events[i]['details'] && events[i]['details']['duration'])
          event.product.duration = events[i]['details']['duration'];

        if (events[i]['details'] && events[i]['details']['property_access_details'])
          event.product.property_access_details = events[i]['details']['property_access_details'];

        if (events[i]['details'] && events[i]['details']['property_contact_comment'])
          event.product.property_contact_comment = events[i]['details']['property_contact_comment'];

        if (events[i]['details'] && events[i]['details']['property_contact_name'])
          event.product.property_contact_name = events[i]['details']['property_contact_name'];

        if (events[i]['details'] && events[i]['details']['property_size_formatted'])
          event.product.property_size_formatted = events[i]['details']['property_size_formatted'];

        if (events[i]['details'] && events[i]['details']['property_contact_linking_phone'])
          event.product.property_contact_linking_phone = events[i]['details']['property_contact_linking_phone'];

        if (events[i]['status']) {
          event.product.status = events[i]['status'];

          if (events[i]['status']['shortcode'] && (events[i]['status']['shortcode'] === "planned"))
            event.className = "event-order-product inc";
          else if (events[i]['status']['shortcode'] && (events[i]['status']['shortcode'] === "done"))
            event.className = "event-order-product todo";
          else
            event.className = "event-order-product archived";
        }

        if (events[i]['details'] && events[i]['details']['options'])
          event.product.options = events[i]['details']['options'].split(',');

        if (events[i]['location'])
          event.product.property =  events[i]['location'];

        providerEvents.push(event);
      }
      else if (events[i].type && events[i].type === "cart_product") {

        const event = {
          type: "cartProduct",
          title: translation().schedule.product_in_cart,
          id: events[i]['id'] ? events[i]['id'] : undefined,
          writable: false,
          deletable: false,
          className: "event-cart-product",
          product: {
            name: translation().schedule.product_in_cart
          }
        };

        if (events[i]['start'])
          event.start = toJsDate(dateInTz(events[i]['start']));

        if (events[i]['end'])
          event.end = toJsDate(dateInTz(events[i]['end']));

        providerEvents.push(event);
      }
    }

    return providerEvents;
  }

  selectSlot(slotInfo) {
    this.setState({
      isCreateAvailabilityOpen: true,
      slotInfo,
    });
  }

  handleSelectEvent(event) {
    this.setState({
      isPopupEventOpen: true,
      event,
    });
  }

  createAvailability(values, slot) {

    if (!values || !slot) return;

    this.props.postAvailability(
      values,
      slot,
      this.handleCloseCreateAvailability
    );
  }

  createUnavailability(values) {

    const { event } = this.state;

    if (!event || !event.start || !values) return;

    this.props.postUnavailability(
      event.start,
      values,
      this.handleCloseCreateUnavailability
    );
  }

  editAvailability(id, values, eventStart) {

    if (!id || !values || !eventStart) return;

    this.props.putAvailability(
      id,
      values,
      eventStart,
      this.handleCloseEventPopup
    );
  }

  editUnavailability(event, values) {

    if (!event || !event.start || !event.id || !values) return;

    this.props.putUnavailability(
      event.id,
      event.start,
      values,
      this.handleCloseEventPopup
    );
  }

  removeAvailability(id) {
    if (!id) return;

    this.props.deleteAvailability(id, this.handleCloseEventPopup);
  }

  removeUnavailability(id) {
    if (!id) return;

    this.props.deleteUnavailability(id, this.handleCloseEventPopup)
  }

  handleCloseEventPopup() {
    this.setState({
      isPopupEventOpen: false,
      event: null
    });
  }

  handleCloseCreateAvailability() {
    this.setState({
      isCreateAvailabilityOpen: false,
      slotInfo: null
    });
  }

  handleOpenCreateUnavailability(event) {
    this.setState({
      isCreateUnavailabilityOpen: true,
      event
    });
  }

  handleCloseCreateUnavailability() {
    this.setState({
      isCreateUnavailabilityOpen: false,
      event: null
    });
  }

  handleView(view) {
    this.props.setCurrentView(view);
  }

  handleOpenCompanyProviders() {
    this.setState({ isCompanyProvidersOpen: true });
  }

  handleShowCompanyProviderCal() {
    this.setState({ isCompanyProvidersOpen: false });
  }

  handleCloseCompanyProviders() {
    this.setState({ isCompanyProvidersOpen: false });
  }

  handleGetCompanyMembers() {
    const {
      getCompanyUsers,
      user
    } = this.props;

    if (user && user.data && user.data.company_admin && user.data.company) {
      getCompanyUsers();
    }
  }

  handleRangeChange() { }

  getDrilldownView() {
    /**
     * Important! : return null for block day redirect that make 2 onNavigates so 2 calls Api
     * Params: (targetDate, currentViewName, configuredViewNames)
     */
    return null;
  }

  handleNavigate(date, view, action = "!date") {

    const clonedDate = new Date(date.getTime());

    if (view === "day" || ( (action.toLowerCase() === "date") || (action === "DATE")) ) {
      this.setDayTimeRange(clonedDate);
    }
    else {
      this.loadEventsView(view, clonedDate);
    }
  }

  loadEventsView(view, providedDate) {

    const eventDate = providedDate ? providedDate : new Date();

    if (view === "month")
      this.setMonthTimeRange(eventDate);
    else if (view === "week")
      this.setWeekTimeRange(eventDate);
    else if (view === "day")
      this.setDayTimeRange(eventDate);
  }

  setDayTimeRange(date) {

    if (!date) return;

    const {
      schedule: { users },
      match
    } = this.props;

    let selected = null;

    if (match && match.params && match.params.id) {
      selected = match.params.id;
    }
    else if (users && users.selected && users.selected.id) {
      selected = users.selected.id;
    }

    /**
     * Clone the date for not alter calendar current date
     * Get the week number from current date (JS date)
     * Then get the current week TimeRange from week number
     * Set start and end for have { start: ../../../00:00:00 , end: (../../../23:59:59) }
     */
    const clonedDate = new Date(date.getTime());

    const start = alterDate(clonedDate, null, null, null, "0", "0", "0", "YYYY-MM-DDTHH:mm:ss");
    const end = alterDate(clonedDate, null, null, null, "23", "59", "59", "YYYY-MM-DDTHH:mm:ss");

    this.props.setCurrentTimeRange({start, end});
    this.props.getProviderEvents({start, end}, selected);
  }

  setMonthTimeRange(date) {

    if (!date) return;

    const {
      schedule: { users },
      match
    } = this.props;

    let selected = null;

    if (match && match.params && match.params.id) {
      selected = match.params.id;
    }
    else if (users && users.selected && users.selected.id) {
      selected = users.selected.id;
    }

    /**
     * Clone the date for not alter calendar current date
     * Check type of view for GET TimeRange start & end
     * Set start and end for have { start: ../../../00:00:00 , end: (../../../23:59:59) }
     * Remove 6 days from start and Add 6 days to end, for have all slots on months views
     */
    const clonedDate = new Date(date.getTime());

    const firstDay = new Date(clonedDate.getFullYear(), clonedDate.getMonth(), 1);
    const lastDay = new Date(clonedDate.getFullYear(), clonedDate.getMonth() + 1, 0);

    const startTimeRangeUtc = createDateUtc(firstDay);
    const endTimeRangeUtc = createDateUtc(lastDay);

    const dateUtcStartAltered = alterDate(startTimeRangeUtc, null, null, null, "0", "0", "0");
    const dateUtcEndAltered = alterDate(endTimeRangeUtc, null, null, null, "23", "59", "59");

    const start = removeFromDate(dateUtcStartAltered, "5", "day", "YYYY-MM-DDTHH:mm:ssZ");
    const end = addToDate(dateUtcEndAltered, "7", "day", "YYYY-MM-DDTHH:mm:ssZ");

    this.props.setCurrentTimeRange({start, end});
    this.props.getProviderEvents({start, end}, selected);
  }

  setWeekTimeRange(date) {

    if (!date) return;

    const {
      schedule: { users },
      match
    } = this.props;

    let selected = null;

    if (match && match.params && match.params.id) {
      selected = match.params.id;
    }
    else if (users && users.selected && users.selected.id) {
      selected = users.selected.id;
    }

    /**
     * Clone the date for not alter calendar current date
     * Get the week number from current date (JS date)
     * Then get the current week TimeRange from week number
     * Set start and end for have { start: ../../../00:00:00 , end: (../../../23:59:59) }
     */
    const clonedDate = new Date(date.getTime());

    const weekNbr = getWeekNumberFromJsDate(clonedDate);

    const weekTimeRange = getDateRangeOfWeek(clonedDate, weekNbr);
    const startTimeRangeUtc = createDateUtc(weekTimeRange.start);
    const endTimeRangeUtc = createDateUtc(weekTimeRange.end);

    const dateUtcStartAltered = alterDate(startTimeRangeUtc, null, null, null, "0", "0", "0");
    const dateUtcEndAltered = alterDate(endTimeRangeUtc, null, null, null, "23", "59", "59");

    const start = formatDate(dateUtcStartAltered, "YYYY-MM-DDTHH:mm:ssZ");
    const end = formatDate(dateUtcEndAltered, "YYYY-MM-DDTHH:mm:ssZ");

    this.props.setCurrentTimeRange({start, end});
    this.props.getProviderEvents({start, end}, selected);
  }

  renderCreateAvailability(slotInfo, isOpen) {

    if (!slotInfo) return;

    const createAvailabilityLoading = this.props.schedule.availabilities.create.loading ? this.props.schedule.availabilities.create.loading : false;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleCloseCreateAvailability}
        style={modalStyles}
      >
        <CreateAvailabilityPopup
          slot={slotInfo}
          onClose={this.handleCloseCreateAvailability}
          createAvailability={this.createAvailability}
          createAvailabilityLoading={createAvailabilityLoading}
          minHour={this.minHour}
          maxHour={this.maxHour}
        />
      </Modal>
    );
  }

  renderEventPopup(event) {

    if (!event || event.type === "cartProduct") return;

    const {
      schedule: {
        availabilities,
        unavailabilities,
        users
      }
    } = this.props;

    const deleteAvailabilityLoading = availabilities.delete.loading ? availabilities.delete.loading : false;
    const editAvailabilityLoading = availabilities.edit.loading ? availabilities.edit.loading : false;
    const deleteUnavailabilityLoading = unavailabilities.delete.loading ? unavailabilities.delete.loading : false;
    const editUnavailabilityLoading = unavailabilities.edit.loading ? unavailabilities.edit.loading : false;

    return (
      <Modal
        isOpen={this.state.isPopupEventOpen}
        onRequestClose={this.handleCloseEventPopup}
        style={modalStyles}
      >
        <ScheduleEventPopup
          editAvailability={this.editAvailability}
          editAvailabilityLoading={editAvailabilityLoading}
          editUnavailability={this.editUnavailability}
          editUnavailabilityLoading={editUnavailabilityLoading}
          event={event}
          deleteAvailabilityLoading={deleteAvailabilityLoading}
          deleteUnavailabilityLoading={deleteUnavailabilityLoading}
          onClose={this.handleCloseEventPopup}
          removeAvailability={this.removeAvailability}
          removeUnavailability={this.removeUnavailability}
          minHour={this.minHour}
          maxHour={this.maxHour}
          readOnly={users && users.selected ? true : false}
        />
      </Modal>
    );
  }

  handleOpenHashDialog() {
    this.setState({ isHashDialogOpen: true });
    this.props.getHash();
  }

  handleCloseHashDialog() {
    this.setState({ isHashDialogOpen: false });
  }

  handleScrollCompanyProviders() {

    const {
      getCompanyUsers,
      schedule: { users }
    } = this.props;

    if (handleInfiniteScroll(".company-users-schedule-table-scroll")) {
      if (users.paging && users.paging.next) {
        if (!users.isLoading) {
          getCompanyUsers(users.paging.next);
        }
      }
    }
  }

  handleSelectCompanyProvider(provider) {

    const {
      getProviderEvents,
      selectScheduleCompanyUser,
      schedule: { currentTimeRange }
    } = this.props;

    if (!provider.id) return;

    selectScheduleCompanyUser(provider);

    getProviderEvents(
      currentTimeRange,
      provider.id,
      () => {
        this.handleShowCompanyProviderCal();
      },
    );
  }

  renderCompanyUsersTable(data) {

    const {
      user,
      provider
    } = this.props;

    if (data && data.length > 0 ) {

      const rows = [];

      for (let i = 0; i < data.length; i++) {
        let row = {};

        if (data[i].id)
          row['id'] = data[i].id;
        else
          row['id'] = 'NaN'

        if (data[i].user && data[i].user.last_name)
          row['last_name'] = data[i].user.last_name;
        else
          row['last_name'] = '-'

        if (data[i].user && data[i].user.first_name)
          row['first_name'] = data[i].user.first_name;
        else
          row['first_name'] = '-';

        rows.push(row);
      }

      return (
        <div className="table-wrapper" style={{marginTop: 35}}>
          <Table style={{marginBottom: 50}}>
            <TableHead>
              <TableRow>
                <TableCell>{translation().schedule.users.dialog.table.last_name}</TableCell>
                <TableCell>{translation().schedule.users.dialog.table.first_name}</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.map((row, index) => {
                  if (row.id !== user.data.id) {
                    return (
                      <Provider
                        key={index}
                        lastName={row.last_name}
                        firstName={row.first_name}
                        id={row.id}
                        onSelect={this.handleSelectCompanyProvider}
                        providerSelfId={provider && provider.data && provider.data.id ? provider.data.id : null}
                      />
                    );
                  }
                  else
                    return false;
                })
              }
            </TableBody>
          </Table>
        </div>
      );
    }
  }

  render() {

    const {
      event,
      isCompanyProvidersOpen,
      isCreateAvailabilityOpen,
      isCreateUnavailabilityOpen,
      isHashDialogOpen,
      slotInfo
    } = this.state;

    const {
      events,
      hash,
      unavailabilities,
      users
    } = this.props.schedule;

    const {
      user,
      provider
    } = this.props;

    const createUnavailabilityLoading = unavailabilities.create.loading ? unavailabilities.create.loading : false;
    const providerEvents = this.convertDataIntoEvent(events.list);

    return (
      <div className="App-view Schedule">
        <div className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-4" style={{textAlign: "left"}}>
                { user && user.data && user.data.company_admin && user.data.company &&
                  <Button
                    variant="contained"
                    className="btn-sync-cal"
                    color="secondary"
                    onClick={this.handleOpenCompanyProviders}
                  >
                    <TodayIcon fontSize="small" style={{marginRight: 4}} />
                    {translation().schedule.users.open_dialog}
                  </Button>
                }
              </div>
              <div className="col-sm-12 col-md-4">
                <h3>{translation().schedule.page_title + (users && users.selected && users.selected.firstName ? " (" + users.selected.firstName + ")": "")}</h3>
              </div>
              <div className="col-sm-12 col-md-4 col-flex-buttons">
                { (!users.selected || (users && users.selected && users.selected.id && (users.selected.id === provider.data.id))) &&
                  <Button
                    variant="contained"
                    className="btn-sync-cal"
                    onClick={this.handleOpenHashDialog}
                  >
                    <SyncIcon fontSize="small" style={{marginRight: 2}} />
                    {translation().schedule.sync_cal.sync}
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="sync-cal-mobile">
          { user && user.data && user.data.company_admin && user.data.company &&
            <Fab
              size="small"
              color="secondary"
              aria-label="Members"
              className="btn-sync-mobile"
              onClick={this.handleOpenCompanyProviders}
            >
              <TodayIcon fontSize="small"/>
            </Fab>
          }
          { (!users.selected || (users && users.selected && users.selected.id && (users.selected.id === provider.data.id))) &&
            <Fab
              size="small"
              color="default"
              aria-label="Synchro"
              className="btn-sync-mobile"
              onClick={this.handleOpenHashDialog}
            >
              <SyncIcon fontSize="small"/>
            </Fab>
          }
        </div>
        <div style={{position: "relative"}}>
          { events.loading &&
            <div className="schedule-load-overlay">
              <div className="center-loader">
                <CircularProgress style={{color: '#FFFFFF'}} size={70}/>
              </div>
            </div>
          }
          <div className={"container calendar-wrapper " + (users.selected ? "read-only" : "")}>
            <div className="row">
              <div className="col-sm-12 calendar-container">
                <ScheduleCalendar
                  components={{
                    week: {
                      event: ScheduleEventWeek
                    },
                    day: {
                      event: ScheduleEventWeek
                    },
                    month: {
                      event: ScheduleEventMonth,
                    },
                    toolbar: ScheduleToolbar
                  }}
                  defaultView={this.defaultView}
                  defaultDate={this.defaultDate}
                  events={providerEvents}
                  formats={this.formats}
                  getDrilldownView={this.getDrilldownView}
                  maxTime={this.maxTime}
                  minTime={this.minTime}
                  messages={this.messages}
                  onNavigate={this.handleNavigate}
                  onRangeChange={this.handleRangeChange}
                  selectSlot={this.selectSlot}
                  onSelectEvent={this.handleSelectEvent}
                  onView={this.handleView}
                  step={this.step}
                  timeslots={this.timeslots}
                  readOnly={users.selected ? true : false}
                />
              </div>
              <div className="clearfix"/>
            </div>
          </div>
          { this.renderCreateAvailability(slotInfo, isCreateAvailabilityOpen) }
          { this.renderEventPopup(event) }
          <Modal
            isOpen={isCreateUnavailabilityOpen}
            onRequestClose={this.handleCloseCreateUnavailability}
            style={modalStyles}
          >
            <CreateUnavailabilityPopup
              createUnavailability={this.createUnavailability}
              createUnavailabilityLoading={createUnavailabilityLoading}
              onClose={this.handleCloseCreateUnavailability}
              maxHour={this.maxHour}
              minHour={this.minHour}
            />
          </Modal>
        </div>
        <Dialog
          aria-describedby="provider-sync-dialog-desc"
          aria-labelledby="provider-sync-dialog-title"
          onClose={this.handleCloseHashDialog}
          open={isHashDialogOpen}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="provider-sync-dialog-title">
            {translation().schedule.sync_cal.title}
          </DialogTitle>
          <DialogContent>
            <div className="provider-sync-modal">
              { hash.value && hash.value.hash &&
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <h4>{translation().schedule.sync_cal.orders}</h4>
                    <Button
                      color="primary"
                      component={HashLink}
                      href={"webcal://cal.wall-market.com/providers/" + hash.value.hash + "?types=order_product" + (config.env_mode.toLowerCase() === "dev" ? "&test=1" : "")}
                      size="small"
                      variant="contained"
                    >
                      {translation().schedule.sync_cal.link}
                    </Button>
                    <p>{translation().schedule.sync_cal.qrcode}</p>
                    <img
                      height={150}
                      src={config.api_url + "file-transformer/qrcode?data=" + encodeURIComponent("webcal://cal.wall-market.com/providers/" + hash.value.hash + "?types=order_product" + (config.env_mode.toLowerCase() === "dev" ? "&test=1" : "")) + "&size=150&save=0&client_id=" + config.api_key}
                      alt="Missing QRCode"
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <h4>{translation().schedule.sync_cal.availability} <span>({translation().schedule.sync_cal.no_required})</span></h4>
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      component={HashLink}
                      className="btn-availability"
                      href={"webcal://cal.wall-market.com/providers/" + hash.value.hash + "?types=availability" + (config.env_mode.toLowerCase() === "dev" ? "&test=1" : "")}
                    >
                      {translation().schedule.sync_cal.link}
                    </Button>
                    <p>{translation().schedule.sync_cal.qrcode}</p>
                    <img
                      alt="Missing QRCode"
                      height={150}
                      src={config.api_url + "file-transformer/qrcode?data=" + encodeURIComponent("webcal://cal.wall-market.com/providers/" + hash.value.hash + "?types=availability" + (config.env_mode.toLowerCase() === "dev" ? "&test=1" : "")) + "&size=150&save=0&client_id=" + config.api_key}
                    />
                  </div>
                  <div className="clearfix"/>
                  <br/>
                  <div className="col-xs-12">
                    <p>
                      {translation().schedule.sync_cal.warning}&nbsp;
                      <a
                        href="https://play.google.com/store/apps/details?id=at.bitfire.icsdroid&hl=fr"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        ICSx⁵
                      </a>.
                    </p>
                  </div>
                  <div className="clearfix"/>
                </div>
              }
              { hash.loading &&
                <div className="center-loader">
                  <CircularProgress size={35} color="secondary"/>
                </div>
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseHashDialog}>{translation().schedule.sync_cal.close}</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          aria-describedby="members-cal-dialog-desc"
          aria-labelledby="members-cal-dialog-title"
          onClose={this.handleCloseCompanyProviders}
          open={isCompanyProvidersOpen}
          onEnter={this.handleGetCompanyMembers}
          disableEscapeKeyDown={users.isLoading || events.loading}
          disableBackdropClick={users.isLoading || events.loading}
          scroll="paper"
        >
          <DialogTitle id="provider-sync-dialog-title">
            {translation().schedule.users.dialog.title}
          </DialogTitle>
          <DialogContent
            style={{maxHeight: 500}}
            className="company-users-schedule-table-scroll"
            onScroll={this.handleScrollCompanyProviders}
          >
            <p>{translation().schedule.users.dialog.description}</p>
            { !events.loading && users && users.list && users.list.length > 0 ? this.renderCompanyUsersTable(users.list) : false }
            { !events.loading && !users.isLoading && (!users.list || users.list.length <= 0) &&
              <p>{translation().company.members.no_member}</p>
            }
            { (events.loading || users.isLoading) &&
              <div className="center-loader">
                <CircularProgress size={35} color="secondary"/>
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCompanyProviders} disabled={events.loading}>{translation().schedule.sync_cal.close}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Schedule.propTypes = {
  clearSchedule: PropTypes.func.isRequired,
  deleteAvailability: PropTypes.func.isRequired,
  deleteUnavailability: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  getHash: PropTypes.func.isRequired,
  getProviderEvents: PropTypes.func.isRequired,
  postAvailability: PropTypes.func.isRequired,
  postUnavailability: PropTypes.func.isRequired,
  putAvailability: PropTypes.func.isRequired,
  putUnavailability: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    availabilities: PropTypes.shape().isRequired,
    unavailabilities: PropTypes.shape().isRequired,
    events: PropTypes.shape().isRequired,
    hash: PropTypes.shape().isRequired,
    currentView: PropTypes.string,
    currentTimeRange: PropTypes.shape(),
    users: PropTypes.shape()
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }),
  selectScheduleCompanyUser: PropTypes.func.isRequired,
  setCurrentTimeRange: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  provider: PropTypes.shape().isRequired
}

export default Schedule;
