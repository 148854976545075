import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TodayIcon from "@material-ui/icons/Today";

function Provider(props) {
  const { id, firstName, lastName, onSelect } = props;

  function handleClick() {
    onSelect({ id, firstName });
  }

  return (
    <TableRow>
      <TableCell>{lastName}</TableCell>
      <TableCell>{firstName}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="open"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleClick}
        >
          <TodayIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

Provider.propTypes = {
  id: PropTypes.any.isRequired,
  lastName: PropTypes.any.isRequired,
  firstName: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Provider;
