import { getApiConfigFromLocation } from './helpers/functions/utils';

export default {
  site_version: 'v2.1.3',
  site_name: 'LightShop Providers',
  site_brand: 'Wall-Market',
  phone_number: '+33 1 84 80 33 36',
  contact_email: 'rs@wall-market.com',
  primary_color: '#FFD700',
  secondary_color: '#F29D06',
  dark_color: '#1A2123',

  env_mode: process.env.NODE_ENV === 'production' ? 'prod' : 'test',
  // env_mode: "PROD",
  // env_mode: "test",

  api_url: getApiConfigFromLocation('api_url'),
  api_key: getApiConfigFromLocation('api_key'),
  api_env: getApiConfigFromLocation('name'),

  /**
   * COOKIES
   */
  access_token_cookie_name: '_Secure-access_token',
  locale_cookie_name: 'locale',
  phone_number_cookie_name: 'phone_number',
  contact_email_cookie_name: 'contact_email',

  /**
   * DEFAULT VALUES
   */
  default_locale: 'fr',
  default_currency: 'EUR',
  default_currency_symbol: '€',
  default_timezone: 'Europe/Paris',
  default_country: 'FR',
  default_unit_system: 'metric',
  open_countries_lang: [
    { code: 'de', name: 'Deutsch' },
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'it', name: 'Italiano' },
    { code: 'no', name: 'Norsk' },
  ],
  open_countries_currencies: [
    { code: 'CAD', name: 'Canadian Dollar' },
    { code: 'CHF', name: 'Franc suisse' },
    { code: 'EUR', name: 'Euro' },
    { code: 'GBP', name: 'British Pound' },
    { code: 'NOK', name: 'Norsk Krone' },
    { code: 'SEK', name: 'Svensk Krona' },
    { code: 'USD', name: 'US Dollar' },
  ],
};
